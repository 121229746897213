export default [
  {
    path: '/transactions',
    name: 'transactions-c-c',
    component: () => import('@/views/transactions/c-c/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/m-c',
    name: 'transactions-m-c',
    component: () => import('@/views/transactions/m-c/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'M-C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/c-m',
    name: 'transactions-c-m',
    component: () => import('@/views/transactions/c-m/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'C-M',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/m-m',
    name: 'transactions-m-m',
    component: () => import('@/views/transactions/m-m/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'M-C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/a-c',
    name: 'transactions-a-c',
    component: () => import('@/views/transactions/a-c/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'M-C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/a-m',
    name: 'transactions-a-m',
    component: () => import('@/views/transactions/a-m/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'M-C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/p-c',
    name: 'transactions-p-c',
    component: () => import('@/views/transactions/p-c/Transactions.vue'),
    meta: {
      title: 'Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'transactions',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'M-C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transactions/send-money',
    name: 'transactions-send-money',
    component: () => import('@/views/transactions/send-money/SendMoney.vue'),
    meta: {
      title: 'Transactions | Send Money - Zerocash',
      requiresAuth: true,
      action: 'send',
      resource: 'money',
      pageTitle: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
        },
        {
          text: 'Send Money',
          active: true,
        },
      ],
    },
  },
]
