export default {
  namespaced: true,
  state: {
    id: null,
  },
  getters: {},
  mutations: {
    SET_ID(state, id) {
      state.id = id
    },
  },
  actions: {},
}
