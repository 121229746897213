/* eslint-disable no-restricted-syntax */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    modules: [],
    activeModules: {},
    dataLoad: false,
  },
  getters: {},
  mutations: {
    SET_MODULES(state, module) {
      state.modules = module
    },
    SET_DATA_LOAD(state, status) {
      state.dataLoad = status
    },
    SET_ACTIVE_MODULES(state, activemodule) {
      state.activeModules = activemodule
    },
  },
  actions: {
    async getModules({ commit }) {
      const modules = []
      commit('SET_DATA_LOAD', true)
      try {
        const { data } = await axios.get('modules')
        commit('SET_ACTIVE_MODULES', data.data)
        // eslint-disable-next-line guard-for-in
        for (const name in data.data) {
          modules.push(data.data[name])
        }
        commit('SET_MODULES', modules)
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        commit('SET_DATA_LOAD', false)
      }
    },
  },
}
